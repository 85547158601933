<template>
  <v-row>
     <v-col cols="12" sm="12" md="12" class="text-left mt-4">
     <v-toolbar-title class="pl-5">{{titleInfo}}</v-toolbar-title>
    <v-spacer></v-spacer>  
    </v-col>
    <v-col cols="12" sm="12" md="1" class="pl-5" style="margin-right:15px; margin-top:5px">
      <!-- <v-select
          v-model="channelType"
          :items="items"
          item-value="id" item-text="name"
          label="Channel #  "
          class="channel-type   "
          style="min-width:100%"
          @input="selectChannelType(channelType)"
          solo
      ><template v-slot:prepend>
        <v-icon color="green darken-2">mdi-television</v-icon>
      </template>
      </v-select> -->
      <v-btn v-if="listChannelType.channel_type ==1"
        large
        depressed
        disabled
      >
      ប៉ុស្តិ៌ 1
      </v-btn>
       <v-btn v-if="listChannelType.channel_type ==2"
        depressed
        disabled
        large
      >
      ប៉ុស្តិ៌ 2
      </v-btn>
       <v-btn v-if="listChannelType.channel_type ==3"
        depressed
        disabled
        large
      >
      ប៉ុស្តិ៌ 3
      </v-btn>
       <v-btn v-if="listChannelType.channel_type ==4"
        depressed
        disabled
        large
      >
      ប៉ុស្តិ៌ 4
      </v-btn>
    </v-col>

    <v-col cols="12" sm="12" md="2" class="text-right pl-0" style="margin-right:0px; margin-top:-15px">
      <v-select
          v-model="currencyType"
          :items="items2"
          item-value="id" item-text="name"
          label="Channel #  "
          class="channel-type   "
          style="min-width:100%"
          @input="selectCurrencyType(currencyType)"
          solo
      ><template v-slot:prepend>
      </template>
      </v-select>
    </v-col>

    <v-col cols="12" sm="12" md="2" class="text-right pl-5" v-if="this.user.role_name !== 'CONTROLLER'">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pl-5"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="ចាប់ផ្តើម"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-col v-if="this.user.role_name !== 'CONTROLLER'"
      cols="12"
      sm="12"
      md="2"
      class="text-right "
    >
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        label="បញ្ចប់"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
      <v-date-picker
        v-model="endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" sm="2" md="2" class="text-right mb-3">
      <v-text-field
        v-model="v_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        class="pl-5"
      />
    </v-col>
    <v-col cols="4" sm="4" md="2" class="text-right pr-5 mb-3 ">
      <v-btn 
        class="white--text btn-custom mr-5"
        color="green darken-1"
        depressed
        @click="getData()"
      >
        SEARCH
      </v-btn>
    </v-col>
  </v-row>
  
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission',"user", "time","listChannelType"],
  data() {
    return {
      data: {
          descending: true,
          sortBy: "_id",
          page: 1,
          rowsPerPage: 10,
          fields: ["_id","user_name", "balance", "amount", "amount_win","app_id",],
          search: '',
          startDate:  new Date().toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10),
          channelType: this.listChannelType.channel_type
      },
      startDate:  new Date().toISOString().substr(0, 10),
      endDate:  new Date().toISOString().substr(0, 10),
      v_startDate: null,
      v_endDate: null,
      v_search:"",
      items: [
        {name:"ប៉ុស្តិ៌ 1", id:1},
        {name:"ប៉ុស្តិ៌ 2", id:2},
        {name:"ប៉ុស្តិ៌ 3", id:3},
        {name:"ប៉ុស្តិ៌ 4", id:4}
      ],
      channelType : {name : "1", id :1},
      items2: [
        {name:"ដុល្លា", id:1},
        {name:"រៀល", id:2},
        {name:"បាត", id:3},
      ],
      currencyType : {name : "1", id :1},
    };
  },
 watch: {
    time: function(newVal) {
    // Emit this information to the parents component
    this.startDate = newVal.startDate;
    this.endDate = newVal.endDate;
    } 
  },
  mounted() {
    if (this.$cookies.get("channel-type")) {
      this.channelType = {name : this.$cookies.get("channel-type"), id : parseInt(this.$cookies.get("channel-type")) };
    }
    if(this.$cookies.get("currency-type")) {
      this.currencyType = {name : this.$cookies.get("currency-type"), id : parseInt(this.$cookies.get("currency-type")) };
    }
  },
 computed: {
   titleInfo: function(){
        return "List Senior Statement"
    },
    reportUrl: function(){
      return "getViewSeniorCurrencyApiBranch" 
    },
 },
  methods: {
    selectChannelType(channelType){
      this.$cookies.set("channel-type", channelType);
      this.channelType = {name : this.$cookies.get("channel-type"), id : parseInt(this.$cookies.get("channel-type")) };
      if(parseInt(this.$cookies.get("channel-type")) == 2 || parseInt(this.$cookies.get("channel-type") )== 3 || parseInt(this.$cookies.get("channel-type")) == 4 ) this.$connectSocket(this.$cookies.get('token'), this.$cookies.get("channel-type"))
      //this.$router.go();
    },
    selectCurrencyType(currencyType){
      this.$cookies.set("currency-type", currencyType);
      this.currencyType = {name : this.$cookies.get("currency-type"), id : parseInt(this.$cookies.get("currency-type")) };
      if(parseInt(this.$cookies.get("currency-type")) == 1 || parseInt(this.$cookies.get("currency-type")) == 2 || parseInt(this.$cookies.get("currency-type") )== 3) this.$connectSocket(this.$cookies.get('token'), this.$cookies.get("currency-type"))
      //this.$router.go();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },
    async getData() {
      this.data.startDate = this.startDate
      this.data.endDate = this.endDate
      this.data.search = this.v_search
      this.data.channelType = this.listChannelType.channel_type
      this.data.currencyType = this.currencyType.id
      console.log("this.reportUrl:",this.reportUrl);
      let preData = {
        data : this.data,
        user_id : this.user._id,
        reportUrl : this.reportUrl
      }
      console.log('preData:', preData);
      this.fetchListStatement(preData).then(() => {
        console.log("into fetch list statement");
            let date = {
              startDate : this.startDate,
              endDate : this.endDate
            }
           this.$emit("child-checkbox", date);
          })
    },
    ...mapActions("$_statementSeniorReports", ["fetchListStatement","fetchChannelType"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom{
        position: relative;
        // right: 250px;
        top: 10px;
        padding: 15px !important;
    }
    .tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.v-btn:not(.v-btn--round).v-size--default{
  padding:15px!important;
}
@media (max-width: 768px) {
.v-menu__content{
  left:63px!important
}
}
</style>
